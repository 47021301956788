/* External dependencies */
import { Col, Container, Div, Row, Text } from 'atomize';
import { navigate } from 'gatsby-plugin-react-intl';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';
import { useSelector } from 'react-redux';

/* Local dependencies */
import { RootState } from '../../redux/store';
import NotFound404 from '../../svg/404';
import { getDefaultPath } from '../apollo-wrapper';
import PrimaryButton from '../buttons/PrimaryButton';

interface RouterProps {
  path?: string | string[];
}

export default function NotFound(path: RouterProps) {
  const { currentUser } = useSelector((state: RootState) => state.login);

  return (
    <Container>
      <Row h="75vh" align="center">
        <Col w="100%" h="auto">
          <Div
            maxW="512px"
            maxH="198px"
            m={{ xs: '0 auto 2rem', md: '0 auto 5rem' }}
            w="100%"
          >
            <NotFound404 style={{ width: '100%' }} />
          </Div>
          <Div m={{ b: { xs: '2rem', md: '3rem' } }}>
            <Text
              tag="h1"
              textColor="table_cell_text_color"
              textSize={{ xs: 'mobileTitle', md: 'title' }}
              textWeight="700"
              textAlign="center"
            >
              <FormattedMessage id="notFoundPage" />
            </Text>
          </Div>
          <Div d="flex" justify="center">
            <PrimaryButton
              onClick={() => navigate(getDefaultPath(currentUser))}
            >
              <FormattedMessage id="goToMainPage" />
            </PrimaryButton>
          </Div>
        </Col>
      </Row>
    </Container>
  );
}
