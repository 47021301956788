/* External dependencies */
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

/* Local dependencies */
import Layout from '../components/layout';
import NotFound from '../components/not-found-404/NotFound';
import formatMessage from '../intl/formatMessage';

export default function NotFoundPage() {
  const title = formatMessage('notFoundPage');
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <NotFound />
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
